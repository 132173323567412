import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllItemMeasurementPositionsTranslationInput } from '../K3DataActionExtension.g';
import { IK3ItemMeasurementPositionsTranslation } from '../K3DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ItemMeasurementPositionsTranslation[]>>retailAction,
    input: (context: ICreateActionContext) => {
        return createGetAllItemMeasurementPositionsTranslationInput({ Paging: { Top: 250 } });
    }
});
