import * as React from 'react';

import { IK3ContainerCsrAndWashCareData } from './k3-container-csr-and-wash-care.data';
import { IK3ContainerCsrAndWashCareProps } from './k3-container-csr-and-wash-care.props.autogenerated';

export interface IK3ContainerCsrAndWashCareViewProps extends IK3ContainerCsrAndWashCareProps<IK3ContainerCsrAndWashCareData> {}

/**
 *
 * K3ContainerCsrAndWashCare component
 * @extends {React.PureComponent<IK3ContainerCsrAndWashCareProps<IK3ContainerCsrAndWashCareData>>}
 */
class K3ContainerCsrAndWashCare extends React.PureComponent<IK3ContainerCsrAndWashCareProps<IK3ContainerCsrAndWashCareData>> {
    public render(): JSX.Element | null {
        const { slots } = this.props;

        if (!slots) {
            return <div className='row'> No Slots Configured</div>;
        }

        return (
            <div {...this.props.renderModuleAttributes(this.props)} id={this.props.id}>
                <div className='row'>
                    <div className='col-sm-6'>{slots.washAndCareModule[0]}</div>
                    {/* <div className='col-sm-1'> </div> */}
                    <div className='col-sm-6'>{slots.csrModule[0]}</div>
                </div>
            </div>
        );
    }
}

export default K3ContainerCsrAndWashCare;
