import { getSelectedProductIdFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetProductCSRClassificationTableByProductInput } from '../K3DataActionExtension.g';
import { IK3ProductCSRClassificationsTable } from '../K3DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ProductCSRClassificationsTable[]>>retailAction,
    input: inputData => {
        const productId = getSelectedProductIdFromActionInput(inputData);
        return createGetProductCSRClassificationTableByProductInput({ Paging: { Top: 250 } }, Number(productId));
    }
});
